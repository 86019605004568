import React from "react";
import { MainScreen } from "./MainScreen";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import { withCookies } from "react-cookie";

import "./styles.css";
import "./album.css";

function MainScreenFromParams(props) {
  let { category } = useParams();
  return (
    <MainScreen
      selectedCategory={category}
      onLangChanged={props.onLangChanged}
      lang={props.lang}
    />
  );
}

function MainScreenFromTwoParams(props) {
  let { ageFilter, category } = useParams();
  return (
    <MainScreen
      selectedAgeFilter={ageFilter}
      selectedCategory={category}
      onLangChanged={props.onLangChanged}
      lang={props.lang}
    />
  );
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: props.cookies.get("lang") || props.defaultLang
    };
    this.onLangChanged = this.onLangChanged.bind(this);
  }

  onLangChanged(lang) {
    this.setState({ lang: lang });
    this.props.cookies.set("lang", lang, { path: "/" });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <MainScreen
              onLangChanged={this.onLangChanged}
              lang={this.state.lang}
            />
          </Route>
          <Route path="/:ageFilter/:category">
            <MainScreenFromTwoParams
              onLangChanged={this.onLangChanged}
              lang={this.state.lang}
            />
          </Route>
          <Route path="/:category">
            <MainScreenFromParams
              onLangChanged={this.onLangChanged}
              lang={this.state.lang}
            />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withCookies(App);
