import React from "react";
import { I18N } from "./Globals";
import { Sites, Filters, LanguageFilters } from "./Globals";

let filterBySelectedCategory = (sites, selectedAgeFilter, selectedCategory, lang) => {
  let languageFilter = LanguageFilters[lang];
  let filterByCategories = Filters[selectedCategory].categories;
  let result = sites;
  if (selectedAgeFilter !== null) {
    result = result.filter(site => {
      return site.grades.includes(selectedAgeFilter);
    })
  }
  result = result.filter(site => {
    let filterResults = filterByCategories.map(c => {
      return c === "*" || site.categories.includes(c);
    });
    return (
      filterResults.some(f => f) &&
      languageFilter.supportedLanguages.includes(site.lang)
    );
  });
  return result;
};

export class SiteList extends React.Component {
  renderSites(sites) {
    return sites.map(site => (
      <div className="col-md-4" key={site.id}>
        <div className="card mb-4 shadow-sm">
          <a href={site.url} target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + "/" + site.img}
              className="card-img-top"
              alt=""
            />
            <div className="card-body">
              <p className="card-text">{site.description}</p>
            </div>
          </a>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">&nbsp;</div>
            {/* <small className="text-muted">{site.grades}</small> */}
          </div>
        </div>
      </div>
    ));
  }

  renderEmptyState(sites) {
    if (sites.length === 0) {
      return <>
        <p className="no_results">{I18N[this.props.lang].no_results}</p>
      </>;
    }
  }

  render() {
    const sites = filterBySelectedCategory(
      Sites,
      this.props.selectedAgeFilter,
      this.props.selectedCategory,
      this.props.lang
    );
    return (
      <div className="album py-5 bg-light">
        <div className="container">
          <div className="row">
            {this.renderEmptyState(sites)}
            {this.renderSites(sites)}            
          </div>
        </div>
      </div>
    );
  }
}

// export const SiteList = props => {
//   return (
//     <div className="album py-5 bg-light">
//       <div className="container">
//         <div className="row">
//           {filterBySelectedCategory(
//             Sites,
//             props.selectedAgeFilter,
//             props.selectedCategory,
//             props.lang
//           ).map(site => (
//             <div className="col-md-4" key={site.id}>
//               <div className="card mb-4 shadow-sm">
//                 <a href={site.url} target="_blank" rel="noopener noreferrer">
//                   <img
//                     src={process.env.PUBLIC_URL + "/" + site.img}
//                     className="card-img-top"
//                     alt=""
//                   />
//                   <div className="card-body">
//                     <p className="card-text">{site.description}</p>
//                   </div>
//                 </a>
//                 <div className="d-flex justify-content-between align-items-center">
//                   <div className="btn-group">&nbsp;</div>
//                   {/* <small className="text-muted">{site.grades}</small> */}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };
