import React from "react";
import { LanguageSwitch } from "./LanguageSwitch";

export const Header = props => {
  return (
    <header className="header-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-3">
            <div className="logo">
              <h2 className="site-logo">Edu<span className="magenta">L</span>ist</h2>
            </div>
          </div>
          <div className="col-lg-8 col-md-9">            
            <LanguageSwitch
              onLangChanged={props.onLangChanged}
              lang={props.lang}
            />
          </div>
        </div>
      </div>
      <div className="nav-switch">
        <i className="fa fa-bars"></i>
      </div>
	  </header>
  );
};
