import React from "react";
import { I18N } from "./Globals";
import { Share } from "./Share";

export const Footer = props => {
  return (
    <footer className="footer-section text-center">                                  
      <div className="container">                              
      <div className="back-to-top"><a href="#">{I18N[props.lang].back_to_top}</a></div>
        <h2 className="section-title mb-5">{I18N[props.lang].subscribe_header}</h2>        
        <p key='footer-email-us'>
          {I18N[props.lang].footer_email_us}
        </p>

        <Share lang={props.lang} />        
        <p className="footer_text">
          {I18N[props.lang].footer_thank_you}
        </p>
      </div>
    </footer>
  );
};

