import React from "react";
import { I18N } from "./Globals";
import { AgeFilters, Filters } from "./Globals";
import { Link } from "react-router-dom";

export class Jumbotron extends React.Component {
  render() {
    return (
      <>
        <section className="intro-section">
          <div className="container text-center">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <h2 className="section-title">
                  {I18N[this.props.lang].header}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <p></p>					
              </div>
            </div>
            <div className="row">
              {
                Object.keys(AgeFilters)
                  .filter(category =>
                    AgeFilters[category].visible.includes(this.props.lang)
                  )
                  .map(category =>
                    <div className="col" key={category}>
                      <div className="milestone">
                        <h2
                          className={
                            this.props.selectedAgeFilter === category
                              ? "selected_age_filter"
                              : "age_filter"
                          }
                        >
                          <Link                    
                            key={category}
                            to={`/${category}/${this.props.selectedCategory}`}
                          >
                            {AgeFilters[category].name[this.props.lang]}
                          </Link>
                        </h2>
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </section>

        <div className="container">
          <ul className="categories-filter controls">
            {Object.keys(Filters)
              .filter(category =>
                Filters[category].visible.includes(this.props.lang)
              )
              .map(category => (
                <li className={
                  this.props.selectedCategory === category
                    ? "control mixitup-control-active"
                    : "control"
                  } 
                  key={category}
                  >
                  <Link                    
                    key={category}
                    to={
                      this.props.selectedAgeFilter !== null
                        ? `/${this.props.selectedAgeFilter}/${category}`
                        : `/${category}`
                    }
                  >
                    {(() => {
                      if (category === 'all') {
                        return <strong>{Filters[category].name[this.props.lang]}</strong>;
                      } else {
                        return Filters[category].name[this.props.lang];
                      }
                    })()}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  }  
}
