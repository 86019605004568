import React from "react";

export const LanguageFilters = {
  en: {
    name: "EN",
    supportedLanguages: ["en"]
  },
  ru: {
    name: "RU",
    supportedLanguages: ["ru"]
  }
};

export const I18N = {
  en: {
    share: "",
    footer_email_us: [
      <a href="mailto:edulistinfo@gmail.com" className="site-btn" key='get-in-touch'>Get in touch</a>
    ],
    footer_thank_you: [
      "To change your cookie preferences please ",
      <button
        id="change-cookie-prefs"
        className="btn btn-light"
        key="footer_thank_you_button"
      >
        click here
      </button>
    ],
    back_to_top: "Back to top",
    header: [
      "Collection of ",
      <span className="purple" key="header_educational_resources">educational resources</span>,
      <br key="header_br" />,
      "on different subjects"
    ],
    subheader:
      "In the responce to our changing world and the COVID-19 pandemic, we have collected and categorized educational platforms, online-schools, virtual tours and classes on different subjects for kids and adults.",
    subscribe_header: "Want to get the updates?",
    subscribe_text: [
      "We are working on adding youtube channels and virtual museum tours.",
      <br key="subscribe_text_br" />,
      "If you want updates on what we are adding, let's keep in touch."
    ],
    subscribe_placeholder: "Leave your email",
    subscribe_button: "Sign Up",
    subscribe_underline: "You can unsubscribe at any time.",
    no_results: "Sorry, no results. We're working on addding more sites"
  },
  ru: {
    share: "",
    footer_email_us: [
      <a href="mailto:edulistinfo@gmail.com" className="site-btn" key="footer_email_us">Оставить email</a>
    ],
    footer_thank_you: [
      "Для изменения настроек cookies ",
      <button
        id="change-cookie-prefs"
        className="btn btn-light"
        key="footer_thank_you_button"
      >
        нажмите здесь
      </button>,
      "."
    ],
    back_to_top: "Наверх",
    header: [
      "Коллекция ",
      <span className="purple">образовательных ресурсов</span>,
      <br />,
      "по разным предметам"
    ],
    subheader:
      "Категоризированная коллекция образовательных ресурсов, онлайн-школ и классов по разным предметам для детей и взрослых.",
    subscribe_header: "Хотите получать обновления?",
    subscribe_text: [
      "Мы работаем над добавлением YouTube каналов и виртуальных музейных туров.",
      <br key="subscribe_text_br" />,
      "Если вам это интересно, подпишитесь на нашу рассылку."
    ],
    subscribe_placeholder: "Ваш email",
    subscribe_button: "Подписаться",
    subscribe_underline: "Вы сможете отписаться в любое время.",
    no_results: "Нет ресурсов по выбранной категории"
  }
};

export const AgeFilters = {
  two_to_five_yo: {
    name: {
      en: [
        "2",
        <React.Fragment key='two_to_five_yo_dash'>&ndash;</React.Fragment>,
        "5", 
        <span key='two_to_five_yo_span'> y.o.</span>
      ],
      ru: [
        "2",
        <React.Fragment key='two_to_five_yo_dash'>&ndash;</React.Fragment>,
        "5", 
        <span key='two_to_five_yo_span'> лет</span>
      ],
    },
    visible: ["en", "ru"]
  },
  k_to_five: {
    name: {
      en: [
        "K",
        <React.Fragment key='k_to_five_dash'>&ndash;</React.Fragment>,
        "5",
        <span key='k_to_five_span'> grade</span>
      ],
      ru: [
        "1",
        <React.Fragment key='k_to_five_dash'>&ndash;</React.Fragment>,
        "5",
        <span key='k_to_five_span'> класс</span>
      ],
    },
    visible: ["en", "ru"]
  },
  six_to_eight: {
    name: {
      en: [
        "6",
        <React.Fragment key='six_to_eight_dash'>&ndash;</React.Fragment>,
        "8",
        <span key='six_to_eight_span'> grade</span>
      ],
      ru: [
        "6",
        <React.Fragment key='six_to_eight_dash'>&ndash;</React.Fragment>,
        "8",
        <span key='six_to_eight_span'> класс</span>
      ],
    },
    visible: ["en", "ru"]
  },
  nine_to_twelve: {
    name: {
      en: [
        "9",
        <React.Fragment key='nine_to_twelve_dash'>&ndash;</React.Fragment>,
        "12",
        <span key='nine_to_twelve_span'> grade</span>
      ],
      ru: [
        "9",
        <React.Fragment key='nine_to_twelve_dash'>&ndash;</React.Fragment>,
        "11",
        <span key='nine_to_twelve_span'> класс</span>
      ],
    },
    visible: ["en", "ru"]
  },
  college_plus: {
    name: {
      en: "College+",
      ru: "Студенты+"
    },
    visible: ["en", "ru"]
  }
};

export const Filters = {
  all: {
    name: {
      en: "All",
      ru: "Все вместе"
    },
    categories: ["*"],
    visible: ["en", "ru"]
  },
  math: {
    name: {
      en: "Math",
      ru: "Математика"
    },
    categories: ["Math", "Geometry", "Logic"],
    visible: ["en", "ru"]
  },
  science: {
    name: {
      en: "Science",
      ru: "Наука"
    },
    categories: [
      "Chemistry",
      "Science",
      "Physics",
      "Biology",
      "Genetics",
      "Research"
    ],
    visible: ["en", "ru"]
  },
  // language: {
  //   name: {
  //     en: "Language",
  //     ru: "Языки"
  //   },
  //   categories: [
  //     "Language",
  //     "Chinese",
  //     "Phonics",
  //     "Literacy",
  //     "ELA",
  //     "Reading"
  //   ],
  //   visible: ["en", "ru"]
  // },
  "social-studies": {
    name: {
      en: "Social Studies",
      ru: "Гуманитарные науки"
    },
    categories: [
      "Social",
      "Social Skills",
      "History",
      "Philosophy",
      "Psychology",
      "Sex Ed"
    ],
    visible: ["en", "ru"]
  },
  "eng-and-tech": {
    name: {
      en: "Engineering & Tech",
      ru: "Технологии"
    },
    categories: ["Coding", "Tech", "STEM", "Engineering", "Research", "Programming"],
    visible: ["en", "ru"]
  },
  language: {
    name: {
      en: "Language",
      ru: "Язык"
    },
    categories: [
      "Language",
      "Chinese",
      "Phonics",
      "Literacy",
      "ELA",
      "Reading",
      "Grammar",
      "Writing",
      "Storytelling"
    ],
    visible: ["en", "ru"]
  },
  reading: {
    name: {
      en: "Reading",
      ru: "Чтение"
    },
    categories: ["Phonics", "Reading"],
    visible: ["en", "ru"]
  },
  arts: {
    name: {
      en: "Arts",
      ru: "Творчество"
    },
    categories: [
      "Arts",
      "Art",
      "Cinema",
      "Design",
      "Illustration",
      "Typography"
    ],
    visible: ["en", "ru"]
  },
  music: {
    name: {
      en: "Music",
      ru: "Музыка"
    },
    categories: ["Music", "Composing"],
    visible: ["en"]
  },
  georgaphy: {
    name: {
      en: "Georgaphy",
      ru: "География"
    },
    categories: ["Geography"],
    visible: ["en"]
  },
  typing: {
    name: {
      en: "Typing",
      ru: "Набор текста"
    },
    categories: ["Typing"],
    visible: ["en", "ru"]
  }
};

export const Sites = [
  {
    id: 1,
    url: "https://conjuguemos.com/",
    img: "img/screens/conjuguemos.jpg",
    description: "Variety of verb, vocabulary and grammar learning games.",
    categories: ["Language", "Grammar"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en",
  },
  {
    id: 2,
    url:
      "https://educators.brainpop.com/2020/02/19/free-brainpop-access-for-schools-affected-by-the-corona-virus/?utm_source=organic&utm_medium=social&utm_campaign=coronavirus&utm_content=free-access",
    img: "img/screens/brainpop.jpg",
    description: "Over 1,000 short animated movies for students in grades K-12",
    categories: ["Math", "Language", "Science"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 3,
    url: "https://www.albert.io/try-albert",
    img: "img/screens/albert.jpg",
    description: "Coverage of all core curriculum and standardized exams.",
    categories: ["Math", "Social", "Science"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 4,
    url: "https://amaze.org/",
    img: "img/screens/amaze.jpg",
    description:
      "Videos that give you all the answers about sex, your body and relationships.",
    categories: ["Sex Ed"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 5,
    url: "https://explorabl.es/",
    img: "img/screens/explorabl.jpg",
    description: "Explorable Explanations, a hub for learning through play!",
    categories: ["Math", "Science", "Tech", "Art"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 6,
    url: "https://www.overdrive.com/apps/libby/",
    img: "img/screens/libby.jpg",
    description:
      "Anyone with a library card can access thousands of ebooks and audiobooks.",
    categories: ["Reading"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 7,
    url: "https://www.canfigureit.com/",
    img: "img/screens/canfigureit.jpg",
    description:
      "A unique way to teach and learn geometric proofs. Proofs simplified.",
    categories: ["Geometry"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 8,
    url: "https://www.complexity-explorables.org/",
    img: "img/screens/complexity.jpg",
    description:
      "A collection of interactive explorable explanations of complex systems.",
    categories: ["Math", "Science", "Biology", "Physics"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 9,
    url: "https://kids.classroomsecrets.co.uk/",
    img: "img/screens/classroomsecrets.jpg",
    description: "Fun and engaging games for preschoolers.",
    categories: ["Math", "Phonics"],
    grades: ['two_to_five_yo'],
    lang: "en"
  },
  {
    id: 10,
    url: "https://www.3plearning.com/distance-teaching/",
    img: "img/screens/3plearning.jpg",
    description:
      "Math (3\u201314), STEMscopes Science (ages 9-16), Wordflyers (ages 12-16).",
    categories: ["Math", "Science", "Literacy"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 11,
    url: "https://lil.school/",
    img: "img/screens/lilschool.jpg",
    description: "Онлайн-школа креативного мышления.",
    categories: ["Art"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight'],
    lang: "ru"
  },
  {
    id: 12,
    url: "https://www.century.tech/",
    img: "img/screens/century.jpg",
    description:
      "Intelligent intervention tool that combines learning science, AI and neuroscience.",
    categories: ["Learning Platform"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 13,
    url: "https://www.activelylearn.com/post/remote-learning",
    img: "img/screens/activelylearn.jpg",
    description:
      "High-Interest Articles, Middle School Assignments, Themes, Text Sets.",
    categories: ["Social", "ELA", "Science"],
    grades: ['six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 14,
    url: "https://app.edu.buncee.com/",
    img: "img/screens/buncee.jpg",
    description:
      "A creation and communication tool for students and educators.",
    categories: ["Communication tool"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 15,
    url: "https://www.breakoutedu.com/funathome",
    img: "img/screens/breakoutedu.jpg",
    description:
      "Platform that brings the challenges of an escape room to the classrom.",
    categories: ["Platform"],
    grades: "K\u201312",
    lang: "en"
  },
  {
    id: 16,
    url: "https://2simple.com/blog/using-purple-mash-when-school-closed/",
    img: "img/screens/2simple.jpg",
    description: "An award-winning educational software for K-5 students.",
    categories: ["Coding", "Art", "Composing"],
    grades: "K\u20135",
    lang: "en"
  },
  {
    id: 17,
    url: "https://kognity.com/support-coronavirus/",
    img: "img/screens/kognity.jpg",
    description:
      "Curriculum-aligned video examples, 3D models and practice tests.",
    categories: ["Textbooks"],
    grades: "College",
    lang: "en"
  },
  {
    id: 18,
    url: "https://www.kotvmeshke.org/free",
    img: "img/screens/kotvmeshke.jpg",
    description: "Ежемесячный журнал для детей от 6 лет.",
    categories: ["Reading"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "ru"
  },
  {
    id: 19,
    url: "https://hippocampus.org/",
    img: "img/screens/hippocampus.jpg",
    description:
      "Core academic web site that delivers video on general education subjects.",
    categories: ["Math", "Science", "Social"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 20,
    url: "https://www.curriki.org/",
    img: "img/screens/curriki.jpg",
    description:
      "Curriculum, lessons and on-demand content free, open, and accessible to all.",
    categories: ["Math", "Science", "Arts"],
    grades: "PreK\u201312",
    lang: "en"
  },
  {
    id: 21,
    url: "https://musiclab.chromeexperiments.com/",
    img: "img/screens/chromeexperiments.jpg",
    description:
      "Learning music more accessible through fun, hands-on experiments.",
    categories: ["Music"],
    grades: "K\u201312",
    lang: "en"
  },
  {
    id: 22,
    url: "https://kahoot.com/family-friends/",
    img: "img/screens/kahoot.jpg",
    description:
      "Learning platform that makes it easy to create, share and play learning games.",
    categories: ["Platform"],
    grades: "PreK\u201312",
    lang: "en"
  },
  {
    id: 23,
    url: "http://www.icompute-uk.com/",
    img: "img/screens/icompute.jpg",
    description: "Computing curriculum for students aged 3-11.",
    categories: ["Programming"],
    grades: "PreK\u2013Middle",
    lang: "en"
  },
  {
    id: 24,
    url: "https://fluencymatters.com/even-more-free-ci/",
    img: "img/screens/fluencymatters.jpg",
    description:
      "Foreign languare educational instructions and materials (ACTFL).",
    categories: ["Language"],
    grades: "1st\u201312",
    lang: "en"
  },
  {
    id: 25,
    url:
      "https://explaineverything.com/keep-calm-and-teach-online-free-explain-everything-for-closed-schools/?utm_source=Twitter&utm_medium=Referral&utm_campaign=KeepCalmTeachOnline",
    img: "img/screens/explaineverything.jpg",
    description:
      "Interactive whiteboard platform for sharing, collaborating and learning.",
    categories: ["Platform"],
    grades: "1st\u201312",
    lang: "en"
  },
  {
    id: 26,
    url: "https://www.getepic.com/",
    img: "img/screens/getepic.jpg",
    description: "Leading Digital Library for Kids 12 and Under.",
    categories: ["Reading"],
    grades: "Age 3 to 12",
    lang: "en"
  },
  {
    id: 27,
    url:
      "https://support.edpuzzle.com/hc/en-us/articles/360039805612-Help-for-schools-affected-by-Covid-19",
    img: "img/screens/edpuzzle.jpg",
    description:
      "Easy-to-use platform allowing you to engage every student, one video at a time.",
    categories: ["Platform"],
    grades: "K\u201312",
    lang: "en"
  },
  {
    id: 28,
    url: "https://go.edmodo.com/distancelearning/",
    img: "img/screens/edmodo.jpg",
    description:
      "Education network that helps connect all learners with resources.",
    categories: ["Platform"],
    grades: "K\u201312",
    lang: "en"
  },
  {
    id: 29,
    url: "https://www.discoveryeducation.com/",
    img: "img/screens/discoveryeducation.jpg",
    description:
      "Standards-aligned, digital curriculum resources and easy-to-use technology.",
    categories: ["STEM"],
    grades: "K\u201312",
    lang: "en"
  },
  {
    id: 30,
    url:
      "https://www.khanacademy.org/about/blog/post/611770255064350720/remote-learning-with-khan-academy-during-school",
    img: "img/screens/khanacademy.jpg",
    description:
      "Build a deep, rock-solid understanding in math, grammar, science and history.",
    categories: ["Math", "Science", "Arts", "ELA", "Grammar"],
    grades: "PreK\u2013College",
    lang: "en"
  },
  {
    id: 31,
    url: "https://www.ixl.com/membership/teachers/trial/closure",
    img: "img/screens/ixl.jpg",
    description: "Standards-based Learning. Adaptive & Individualized.",
    categories: ["Math", "Science", "Language"],
    grades: "PreK\u201312",
    lang: "en"
  },
  {
    id: 32,
    url: "https://flat.io/edu",
    img: "img/screens/flat.jpg",
    description:
      "First music notation software that anyone can use, including kids",
    categories: ["Music"],
    grades: "PreK\u201312",
    lang: "en"
  },
  {
    id: 33,
    url:
      "https://bookcreator.com/2020/03/support-for-schools-affected-by-coronavirus/",
    img: "img/screens/bookcreator.jpg",
    description:
      "Simple tool for creating awesome digital books and teaching resources.",
    categories: ["Platform"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 34,
    url: "https://www.matific.com/us/en-us/home/",
    img: "img/screens/matific.jpg",
    description: "Math educational platform designed by pedagogical experts.",
    categories: ["Math", "Platform"],
    grades: "K\u20136",
    lang: "en"
  },
  {
    id: 35,
    url: "https://www.accessibyte.com/",
    img: "img/screens/accessibyte.jpg",
    description:
      "Fully accessible platform for blind and visually impaired students.",
    categories: ["Typing", "Education"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 36,
    url: "http://info.activedinc.com/covid",
    img: "img/screens/activedinc.jpg",
    description: "Web-based dynamic lessons PreK through 2nd grade curriculum.",
    categories: ["Math", "Language", "Active"],
    grades: "PreK\u20132",
    lang: "en"
  },
  {
    id: 37,
    url: "https://www.activehistory.co.uk/",
    img: "img/screens/activehistory.jpg",
    description:
      "Educational simulations, decision-making games, quizzes and worksheets.",
    categories: ["History"],
    grades: "Age 11 to 18",
    lang: "en"
  },
  {
    id: 38,
    url: "https://www.ageoflearning.com/",
    img: "img/screens/ageoflearning.jpg",
    description: "Child Development and Early Learning Framework.",
    categories: ["Literacy", "Math", "Science"],
    grades: "Age 2 to 12",
    lang: "en"
  },
  {
    id: 39,
    url: "https://dsl.richmond.edu/panorama/",
    img: "img/screens/panorama.jpg",
    description:
      "Historical atlas of the United States for the twenty-first century.",
    categories: ["History"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 40,
    url: "https://www.arcademics.com/",
    img: "img/screens/arcademics.jpg",
    description: "Free multiplayer educational, math and language arts games.",
    categories: ["Math", "Language"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "en"
  },
  {
    id: 41,
    url: "https://school.bighistoryproject.com/bhplive",
    img: "img/screens/bighistoryproject.jpg",
    description:
      "Resource full of great activities to keep you entertained and test your learning.",
    categories: ["History"],
    grades: ['six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 42,
    url: "https://www.canfigureit.com/",
    img: "img/screens/canfigureit.jpg",
    description:
      "A unique way to teach and learn geometric proofs. Proofs simplified.",
    categories: ["Geometry"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 43,
    url: "https://www.blockscad3d.com/",
    img: "img/screens/blockscad3d.jpg",
    description:
      "3D modeling tool that helps users to learn math and coding concepts.",
    categories: ["Coding"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "en"
  },
  {
    id: 44,
    url: "https://www.centervention.com/",
    img: "img/screens/centervention.jpg",
    description:
      "Behavior interventions helping students to practice emotional skills.",
    categories: ["Social Skills"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "en"
  },
  {
    id: 45,
    url: "https://www.bunkhistory.org/",
    img: "img/screens/bunkhistory.jpg",
    description:
      "The web’s most interesting writing and thinking about the American past.",
    categories: ["History"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 46,
    url: "http://www.k5technologycurriculum.com/covid19/",
    img: "img/screens/k5technologycurriculum.jpg",
    description: "Technology lessons and activities for grades K-5.",
    categories: ["Tech"],
    grades: ['k_to_five'],
    lang: "en"
  },
  {
    id: 47,
    url: "https://www.betterchinese.com/",
    img: "img/screens/betterchinese.jpg",
    description: "Chinese language and culture skills in a spiral-up approach.",
    categories: ["Chinese"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 48,
    url: "https://www.alchem.ie/",
    img: "img/screens/alchemie.jpg",
    description: "Game-based learning modules of college-level chemistry.",
    categories: ["Chemistry"],
    grades: ['nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 49,
    url:
      "https://www.travelandleisure.com/attractions/museums-galleries/museums-with-virtual-tours",
    img: "img/screens/travelandleisure.jpg",
    description: "Virtual Museum Tours You Can Take on Your Couch.",
    categories: ["Art"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 50,
    url: "https://www.squigglepark.com/",
    img: "img/screens/squigglepark.jpg",
    description: "Reading game that builds strong readers through play.",
    categories: ["Reading"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 51,
    url:
      "https://www.explorelearning.com/index.cfm?method=Controller.dspFreeAccount&_ga=2.32190458.558305376.1569190470-443864285.1556293744",
    img: "img/screens/gizmos.jpg",
    description: "World's largest library of math & science simulations.",
    categories: ["Math", "Science"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 52,
    url: "https://www.science4us.com/trial/",
    img: "img/screens/science4us.jpg",
    description:
      "Learning activities that can be completed in as little as ten minutes.",
    categories: ["Science"],
    grades: ['k_to_five'],
    lang: "en"
  },
  {
    id: 53,
    url: "https://www.facinghistory.org/",
    img: "img/screens/facinghistory.jpg",
    description:
      "Civics, social studies, humanities, and language arts education.",
    categories: ["History", "Social"],
    grades: ['six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 54,
    url: "https://www.izzit.org/",
    img: "img/screens/izzit.jpg",
    description: "Up-to-date information on what's happening in the world.",
    categories: ["Math", "Science", "Art", "Geography"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 55,
    url:
      "https://www.mangahigh.com/en-gb/blog/2020-03-06-free-access-schools-online-maths-coronavirus",
    img: "img/screens/mangahigh.jpg",
    description: "Adaptive quizzes, innovative games and social competitions.",
    categories: ["Math", "Coding"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 56,
    url: "https://info2.jove.com/requestaccess",
    img: "img/screens/jove.jpg",
    description:
      "Videos of scientific experiments from the top laboratories around the globe.",
    categories: ["Biology", "Chemistry", "Genetics"],
    grades: ['college_plus'],
    lang: "en"
  },
  {
    id: 57,
    url: "https://labsland.com/en",
    img: "img/screens/labsland.jpg",
    description:
      "LabsLand connects schools and universities with real labs (Arduino, Electronics, Physics...)",
    categories: ["Science", "Engineering", "Tech", "Coding"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 58,
    url: "https://www.macbethacademy.org/",
    img: "img/screens/macbethacademy.jpg",
    description:
      "Non-profit organization dedicated to enriching exceptional students.",
    categories: ["Math", "Science", "Art", "Music"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 59,
    url: "https://education.minecraft.net/",
    img: "img/screens/minecraft.jpg",
    description: "Lessons, built challenges and connections to educators.",
    categories: ["Tech", "Science", "History"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 60,
    url: "https://www.musicfirst.com/",
    img: "img/screens/musicfirst.jpg",
    description:
      "Comprehensive learning management system for music education.",
    categories: ["Music"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 61,
    url: "https://www.musictheory.net/",
    img: "img/screens/musictheory.jpg",
    description: "Free online content and premium apps for music education.",
    categories: ["Music"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 62,
    url: "https://www.massolit.io/",
    img: "img/screens/massolit.jpg",
    description: "Over 2,800 lectures across more than 400 courses.",
    categories: ["History", "Philosophy", "Psychology"],
    grades: "High\u2013College+",
    lang: "en"
  },
  {
    id: 63,
    url: "https://markkistler.com/",
    img: "img/screens/markkistler.jpg",
    description: "Art camps and online classes from the Pop Culture Icon.",
    categories: ["Art"],
    grades: "K\u2013College+",
    lang: "en"
  },
  {
    id: 64,
    url: "https://www.typing.com/",
    img: "img/screens/typing.jpg",
    description: "Extensive Keyboarding Curriculum and So Much More.",
    categories: ["Typing"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 65,
    url: "https://www.typingclub.com/",
    img: "img/screens/typingclub.jpg",
    description: "Typing lesson plan from beginner to advanced.",
    categories: ["Typing"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 66,
    url: "https://www.stmath.com/home-access",
    img: "img/screens/stmath.jpg",
    description:
      "More than 35,000 puzzles with interactive representations of math topics that align to all state standards.",
    categories: ["Math"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "en"
  },
  {
    id: 67,
    url: "https://phet.colorado.edu/",
    img: "img/screens/phet.jpg",
    description:
      "Interactive math and science simulations in game-like environment.",
    categories: ["Math", "Science", "Physics", "Coding"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 68,
    url: "https://www.newamericanhistory.org/",
    img: "img/screens/newamericanhistory.jpg",
    description:
      "Tools and resources are free and freely available to all educators.",
    categories: ["History", "Social", "Literacy"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 69,
    url: "https://mysteryscience.com/school-closure-planning",
    img: "img/screens/mysteryscience.jpg",
    description: "Open-and-go lessons that inspire kids to love science.",
    categories: ["Science"],
    grades: ['k_to_five'],
    lang: "en"
  },
  {
    id: 70,
    url: "https://info.lumenlearning.com/transition-spring2020",
    img: "img/screens/lumenlearning.jpg",
    description:
      "Courses that improve learning, covering 50+ high-enrollment college subjects.",
    categories: ["Science", "Math", "Social", "Tech"],
    grades: ['college_plus'],
    lang: "en"
  },
  {
    id: 71,
    url: "https://ca.pbslearningmedia.org/",
    img: "img/screens/pbs.jpg",
    description:
      "FREE, standards-aligned videos, interactives and lesson plans.",
    categories: ["STEM", "Language", "Art"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 72,
    url: "https://online.kidsdiscover.com/",
    img: "img/screens/kidsdiscover.jpg",
    description: "Online platform for elementary and middle grade school.",
    categories: ["Language", "Science", "Platform"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "en"
  },
  {
    id: 73,
    url: "https://www.ck12.org/",
    img: "img/screens/ck12.jpg",
    description: "Real world simulations, personalized practice questions.",
    categories: ["Math", "Social", "Science"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 74,
    url: "https://lightsailed.com/",
    img: "img/screens/lightsailed.jpg",
    description:
      "Access to thousands of outstanding fiction and nonfiction texts.",
    categories: ["Reading", "Platform"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 75,
    url: "https://ncase.me/",
    img: "img/screens/ncase.jpg",
    description: "Things you can play to understand consepts.",
    categories: ["Social", "Science"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 76,
    url: "https://classroommagazines.scholastic.com/support/learnathome.html",
    img: "img/screens/scholastic.jpg",
    description:
      "Authentic Texts and Digital Content that Fits Your Curriculum.",
    categories: ["Reading", "Science"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 77,
    url: "https://kreativity.ru/",
    img: "img/screens/kreativity.jpg",
    description:
      "Школа творчества. Рисование, валяние из шерсти, вязание крючком.",
    categories: ["Art"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight'],
    lang: "ru"
  },
  {
    id: 78,
    url: "https://synchronize.ru/",
    img: "img/screens/synchronize.jpg",
    description:
      "Онлайн-курсы живописи, кино, истории, моды, философии, архитектуры и дизайна.",
    categories: ["Art", "History", "Cinema"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "ru"
  },
  {
    id: 79,
    url: "https://logiclike.com/",
    img: "img/screens/logiclike_en.jpg",
    description: "Logic games for kids age 5 and up.",
    categories: ["Math", "Logic"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 80,
    url: "https://uchi.ru/",
    img: "img/screens/uchi.jpg",
    description: "Интерактивная образовательная онлайн-платформа.",
    categories: ["Language", "Math", "Science"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "ru"
  },
  {
    id: 81,
    url: "https://outschool.com/",
    img: "img/screens/outschool.jpg",
    description:
      "Small group video chat classes—created and taught by inspiring teachers.",
    categories: ["Math", "Science", "Art", "Music", "Tech"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 82,
    url: "https://logiclike.com/",
    img: "img/screens/logiclike.jpg",
    description:
      "Онлайн курс логики в игровой форме для детей от 5 лет и взрослых.",
    categories: ["Math", "Logic"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "ru"
  },
  {
    id: 83,
    url: "https://foxford.ru/",
    img: "img/screens/foxford.jpg",
    description: "Онлайн платформа для подготовки к ЕГЭ, ОГЭ, олимпиадам.",
    categories: ["Math", "Science", "History", "Tech"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "ru"
  },
  {
    id: 84,
    url: "https://brilliant.org/",
    img: "img/screens/brilliant.jpg",
    description:
      "Build quantitative skills in math, science, and computer science with fun and challenging interactive explorations.",
    categories: ["Math", "Logic", "Science", "Tech"],
    grades: ['six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "en"
  },
  {
    id: 85,
    url: "https://www.littlepassports.com/",
    img: "img/screens/littlepassports.jpg",
    description:
      "Little Passports creates award-winning subscriptions for kids that inspire them to learn about the world.",
    categories: ["Science", "Geography"],
    grades: ['two_to_five_yo', 'k_to_five', 'six_to_eight'],
    lang: "en"
  },
  {
    id: 86,
    url: "https://lettersafar.com/",
    img: "img/screens/lettersafar.jpg",
    description:
      "An entertaining, nostalgic, and refreshingly different way to learn by receiving a handcrafted letters.",
    categories: ["Geography"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve'],
    lang: "en"
  },
  {
    id: 87,
    url: "http://enot-writer.ru/",
    img: "img/screens/enotwriter.jpg",
    description:
      "Онлайн-курс «Как стать писателем». За 2 месяца ребёнок напишет первую книгу.",
    categories: ["Grammar", "Writing", "Storytelling"],
    grades: ['k_to_five', 'six_to_eight'],
    lang: "ru"
  },
  {
    id: 88,
    url: "https://www.ratatype.ru/",
    img: "img/screens/ratatype.jpg",
    description: "Уроки скоростной слепой печати.",
    categories: ["Typing"],
    grades: ['k_to_five', 'six_to_eight', 'nine_to_twelve', 'college_plus'],
    lang: "ru"
  },
  {
    id: 89,
    url: "https://storytellers.online/",
    img: "img/screens/storytellers.jpg",
    description:
      "Сообщество авторов, людей работающих в рекламе, PR, SMM. Платформа с видео уроками, практическими курсами  и советами сторителлерам.",
    categories: ["Storytelling"],
    grades: ['college_plus'],
    lang: "ru"
  },
  {
    id: 90,
    url: "https://bangbangeducation.ru/",
    img: "img/screens/bangbangeducation.jpg",
    description:
      "Bang Bang Education — это онлайн-школа дизайна и иллюстрации предлагающая курсы и интенсивы от ведущих экспертов индустрии.",
    categories: ["Design", "Illustration", "Typography", "Programming"],
    grades: ['nine_to_twelve', 'college_plus'],
    lang: "ru"
  },
  {
    id: 91,
    url: "https://inflat-course.ru/",
    img: "img/screens/inflat.jpg",
    description:
      "Практический курс, написанный дизайнером-иллюстратором для тех, кто хочет научиться рисовать актуальную коммерческую графику для веб и анимации.",
    categories: ["Design", "Illustration"],
    grades: ['nine_to_twelve', 'college_plus'],
    lang: "ru"
  }
];
