import React from "react";
import { Header } from "./Header";
import { Jumbotron } from "./Jumbotron";
import { Footer } from "./Footer";
import { SiteList } from "./SiteList";

export class MainScreen extends React.Component {
  render() {
    const selectedAgeFilter = this.props.selectedAgeFilter || null;
    const selectedCategory = this.props.selectedCategory || "all";
    return (
      <>
        <Header
          onLangChanged={this.props.onLangChanged}
          lang={this.props.lang}
        />
        <main role="main">
          <Jumbotron
            selectedAgeFilter={selectedAgeFilter}
            selectedCategory={selectedCategory}            
            lang={this.props.lang}
          />
          <SiteList
            selectedAgeFilter={selectedAgeFilter}
            selectedCategory={selectedCategory}
            lang={this.props.lang}
          />
        </main>
        <Footer lang={this.props.lang} />
      </>
    );
  }
}
