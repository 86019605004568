import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/js/brands";

export const Share = props => {
  return (
    <div className="social-links">
      <a
        href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.edulist.info"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "facebook"]} />
      </a>
      <a
        href="https://twitter.com/share?url=http%3A%2F%2Fwww.edulist.info"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "twitter"]} />
      </a>
    </div>
  );  
};
