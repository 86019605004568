import React from "react";
import { LanguageFilters } from "./Globals";

export class LanguageSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.handleLangChange = this.handleLangChange.bind(this);
  }

  handleLangChange(event) {
    if (this.props.onLangChanged) {
      this.props.onLangChanged(event.target.value);
    }
  }

  renderSeparator(currentIndex) {
    if (currentIndex < Object.keys(LanguageFilters).length - 1) {
      return (<div className='lang-separator' />);
    }
  }

  render() {
    return (
      <div id="lang_toggler" className="btn-group btn-group-toggle">
        {Object.keys(LanguageFilters).map((lang, idx) => (
          <React.Fragment key={`lang-switch-${lang}`}>
            <label
              className={
                this.props.lang === lang
                  ? "btn btn-secondary active"
                  : "btn btn-secondary"
              }
              key={`lang-switch-${lang}`}
            >
              <input
                type="radio"
                name="lang"
                value={lang}
                onChange={this.handleLangChange}
                checked={this.props.lang === lang}
              />
              {LanguageFilters[lang].name}
            </label>
            {this.renderSeparator(idx)}
          </React.Fragment>
        ))}
      </div>
    );
  }
}
